<template>
  <button class="btn btn-block mt-4" @click="onClick" :disabled="isDisabled">
    <div v-if="!isLoading">{{ buttonText }}</div>
    <div
      v-if="isLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <span class="sr-only" />
    </div>
  </button>
</template>

<script>
export default {
  name: 'DashboardSettingsButton',
  props: ['buttonText', 'onClick', 'maxWidth', 'isDisabled', 'isLoading'],
};
</script>

<style lang="scss" scoped>
.btn {
  background-color: #cb8f2c;
  height: 44px;
  text-transform: uppercase;
  color: white;
  max-width: 215px;
  &:hover {
    color: white;
  }
  &:active {
    transform: scale(1.01);
    color: white;
  }
}
@media (max-width: 640px) {
  .btn {
    max-width: 100%;
  }
}
</style>
