<template>
  <transition name="enter-left">
    <div class="sidenav-wrapper" v-if="showSidebar" @click="closeSidebar">
      <div id="left-side-nav" class="sidenav">
        <img
          class="logo"
          src="/assets/analytics-left-sidebar-logo.png"
          alt="analytics-right-sidebar-logo"
        />
        <div class="content">
          <div
            v-for="(sidebarItem, index) in sidebarItems"
            :key="index"
            :class="
              `d-flex sidebar-item ${
                index === currentIndex ? 'current-item' : ''
              }`
            "
            @click="handleItemClick(index, sidebarItem.contentName)"
          >
            <div class="icon d-inline-block">
              <img
                :src="`/assets/${sidebarItem.icon}.svg`"
                :alt="sidebarItem.label"
              />
            </div>
            <div class="sidebar-item-label">
              {{ sidebarItem.label }}
            </div>
          </div>
        </div>

        <button
          class="sign-out btn btn-light btn-block text-uppercase font-weight-bold"
          @click="logout"
        >
          Sign Out
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UsersService from '../../services/UsersService';

export default {
  name: 'LeftSidebar',
  mixins: [UsersService],
  components: {},
  data() {
    return {
      sidebarItems: [],
      className: '',
      currentIndex: 0,
    };
  },
  props: {
    updateContent: {
      type: Function,
      default: () => ({}),
    },
  },
  methods: {
    ...mapActions('sidebarInfo', [
      'setCurrentInfoComponentName',
      'watchShowSidebar',
      'unwatchShowSidebar',
      'changeShowSidebar',
    ]),
    ...mapActions('users', ['clearUser']),
    handleItemClick(index, contentName) {
      this.currentIndex = index;
      this.updateContent(contentName);
      this.setCurrentInfoComponentName('Close');
    },
    logout() {
      this.$auth.logout().then(() => {
        this.clearUser();
        this.$router.push('/');
      });
    },
    closeSidebar(e) {
      const smBreakPoint = 640;
      if (e.target.id !== 'left-side-nav' && window.innerWidth <= smBreakPoint)
        this.changeShowSidebar();
    },
  },
  computed: {
    ...mapGetters({ showSidebar: 'sidebarInfo/getShowSidebar' }),
  },
  async created() {
    try {
      this.sidebarItems = [
        {
          label: 'Submissions',
          contentName: 'Submissions',
          icon: 'submissions-white',
        },
        {
          label: 'Settings',
          contentName: 'Settings',
          icon: 'settings-white',
        },
      ];
    } catch (err) {
      console.log(err);
      this.isLoading = false;
    }
  },
  mounted() {
    this.watchShowSidebar();
  },
  destroyed() {
    this.unwatchShowSidebar();
  },
};
</script>

<style lang="scss" scoped>
.sidenav-wrapper {
  height: 100vh;
  width: 280px;
  position: fixed;
  //top: 0;
  //left: 0;
  z-index: 1;
  overflow: auto;
}
.sidenav {
  height: 100vh;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //align-items: center;
  //justify-content: center;
  //overflow: visible;
  background-color: #2a4869;
  //transition: 0.2s;
  //padding-top: 40px;
  padding: 50px 15px;
}

//.header {
//  position: fixed;
//  top: 40px;
//  left: 43px;
//  color: #ffffff;
//  font-size: 14px;
//  font-weight: bold;
//  letter-spacing: 0;
//  line-height: 17px;
//  text-align: center;
//
//  div {
//    opacity: 0.5;
//  }
//
//  img {
//    margin: 17px 0 25px 0;
//  }
//
//  img.mentor {
//    margin-top: -50px;
//  }
//}
.logo {
  height: 24.5px;
  width: 202.3px;
}

.content {
  text-align: left;
  margin: 0 41px 0 30px;

  .sidebar-item {
    margin-bottom: 50px;
    color: white;
    opacity: 0.5;
    //text-transform: uppercase;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0;
    //line-height: 19px;
    cursor: pointer;
    align-items: center;

    &.current-item {
      color: white;
      opacity: 1;
    }
    &-label {
      padding-left: 19px;
    }
  }
}

.sign-out {
  width: 200px;
  align-self: center;
  //position: absolute;
  //bottom: 40px;
  //left: 40px;
}
@media (max-width: 640px) {
  .sidenav-wrapper {
    width: 100%;
  }
  .sidenav {
    width: 315px;
  }
  .content {
    margin: 0;
  }
  // transition animation
  .enter-left-enter-active {
    animation: enter-left 0.5s;
  }
  .enter-left-leave-active {
    animation: enter-left 0.5s reverse;
  }
  @keyframes enter-left {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
}
</style>
