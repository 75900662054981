export const currencyOptions = {
  utilizationFactor: {
    currency: null,
    distractionFree: false,
    precision: 1,
    locale: 'en',
    allowNegative: false,
  },
  baseCost: {
    currency: { prefix: '$' },
    distractionFree: false,
    precision: 0,
    locale: 'en',
    allowNegative: false,
  },
  multiSeatDiscount: {
    currency: { suffix: '%' },
    distractionFree: false,
    precision: 0,
    locale: 'en',
    allowNegative: false,
    valueRange: { max: 100 },
  },
  trainingCost: {
    currency: { prefix: '$' },
    distractionFree: false,
    precision: 0,
    locale: 'en',
    allowNegative: false,
  },
};
export const submitMessages = {
  success: 'Thank You! Your submission has been received.',
  error: 'Something went wrong.  Please try again.',
};
