<template>
  <div class="dashboard container-fluid p-0 m-0">
    <LeftSidebar :updateContent="updateCurrentSectionName" />
    <component v-bind:is="currentSectionName"> </component>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import LeftSidebar from '../components/Dashboard/LeftSidebar';
import Submissions from './dashboard/Submissions.vue';
import Settings from './dashboard/Settings.vue';
import DashboardHeaderImage from '@/components/Dashboard/DashboardHeaderImage';

export default {
  name: 'Dashboard',
  components: {
    LeftSidebar,
    Submissions,
    Settings,
    DashboardHeaderImage,
  },
  data() {
    return {
      currentSectionName: 'Submissions',
    };
  },
  computed: {
    ...mapGetters('users', ['getCurrentSession']),
    ...mapState('users', ['state']),
  },
  methods: {
    ...mapActions({
      clearUser: 'users/clearUser',
    }),
    // ...mapActions('users', ['clearUser']),
    updateCurrentSectionName(sectionName) {
      this.currentSectionName = sectionName;
    },
  },
  // created() {
  //   if (!this.state && !Cookies.get('token')) {
  //     this.$router.push("/");
  //   }
  // }
};
</script>

<style scoped>
.dashboard {
  display: flex;
  /*flex-direction: column;*/
  /*align-items: flex-start;*/
  /*justify-content: flex-start;*/
  height: 100%;
  width: 100%;
  overflow: auto;
}

body {
  font-size: 0.875rem;
}

/*.feather {*/
/*  width: 16px;*/
/*  height: 16px;*/
/*  vertical-align: text-bottom;*/
/*}*/

/*
   * Sidebar
   */

/*.sidebar {*/
/*  position: fixed;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  z-index: 100; !* Behind the navbar *!*/
/*  padding: 0;*/
/*  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);*/
/*}*/

/*.sidebar-sticky {*/
/*  position: -webkit-sticky;*/
/*  position: sticky;*/
/*  top: 48px; !* Height of navbar *!*/
/*  height: calc(100vh - 48px);*/
/*  padding-top: 0.5rem;*/
/*  overflow-x: hidden;*/
/*  overflow-y: auto; !* Scrollable contents if viewport is shorter than content. *!*/
/*}*/

/*.sidebar .nav-link {*/
/*  font-weight: 500;*/
/*  color: #333;*/
/*}*/

/*.sidebar .nav-link .feather {*/
/*  margin-right: 4px;*/
/*  color: #999;*/
/*}*/

/*.sidebar .nav-link.active {*/
/*  color: #007bff;*/
/*}*/

/*.sidebar .nav-link:hover .feather,*/
/*.sidebar .nav-link.active .feather {*/
/*  color: inherit;*/
/*}*/

/*.sidebar-heading {*/
/*  font-size: 0.75rem;*/
/*  text-transform: uppercase;*/
/*}*/

/*!**/
/*   * Navbar*/
/*   *!*/

/*.navbar-brand {*/
/*  padding-top: 0.75rem;*/
/*  padding-bottom: 0.75rem;*/
/*  font-size: 1rem;*/
/*  background-color: rgba(0, 0, 0, 0.25);*/
/*  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);*/
/*}*/

/*.navbar .form-control {*/
/*  padding: 0.75rem 1rem;*/
/*  border-width: 0;*/
/*  border-radius: 0;*/
/*}*/

/*.form-control-dark {*/
/*  color: #fff;*/
/*  background-color: rgba(255, 255, 255, 0.1);*/
/*  border-color: rgba(255, 255, 255, 0.1);*/
/*}*/

/*.form-control-dark:focus {*/
/*  border-color: transparent;*/
/*  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);*/
/*}*/

/*!**/
/*   * Utilities*/
/*   *!*/

/*.border-top {*/
/*  border-top: 1px solid #e5e5e5;*/
/*}*/
/*.border-bottom {*/
/*  border-bottom: 1px solid #e5e5e5;*/
/*}*/
</style>
