<template>
  <div class="dashboard-breadcrumb">
    <img class="logo" :src="logo" :alt="titleText" />
    <div class="text">{{ titleText }}</div>
  </div>
</template>

<script>
export default {
  name: 'DashboardBreadcrumb',
  props: ['logo', 'titleText'],
};
</script>

<style lang="scss" scoped>
.dashboard-breadcrumb {
  display: flex;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  .logo {
    width: 2rem;
    margin-right: 0.75rem;
  }
  .text {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
  }
}
</style>
