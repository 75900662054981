import $http from './ApiService';
export default {
  methods: {
    inviteUser(payload) {
      return $http.post(`/users/invite`, payload);
    },
    registerUser(payload) {
      return $http.post('/users/register', payload);
    },
    getUsers() {
      return $http.get('/users');
    },
    deleteUser(uid) {
      return $http.delete(`/users/${uid}`);
    },
    resetPassword(payload) {
      return $http.post('/users/reset-password', payload);
    },
    isAdmin() {
      return $http.get('/users/isAdmin');
    },
  },
};
