<template>
  <div class="settings container-fluid p-0">
    <DashboardHeaderImage image-path="/assets/team-management.png" />
    <div class="content-wrapper">
      <div class="content">
        <DashboardBreadcrumb
          logo="/assets/settings-navy.svg"
          title-text="Settings"
        />
        <!--        <div class="d-flex align-items-center py-12">-->
        <!--          <img class="mr-3 w-8" src="/assets/submissions-navy.svg" />-->
        <!--          <div class="text-2xl font-bold">Settings</div>-->
        <!--        </div>-->
        <div class="w-full p-0">
          <CalculatorParameters />
          <ChangePassword />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalculatorParameters from '@/components/DashboardSettings/CalculatorParameters/CalculatorParameters';
import ChangePassword from '@/components/DashboardSettings/ChangePassword/ChangePassword';
import DashboardHeaderImage from '@/components/Dashboard/DashboardHeaderImage';
import DashboardBreadcrumb from '@/components/Dashboard/DashboardBreadcrumb';

export default {
  name: 'Settings',
  components: {
    DashboardBreadcrumb,
    DashboardHeaderImage,
    ChangePassword,
    CalculatorParameters,
  },
};
</script>

<style lang="scss" scoped>
.settings {
  width: calc(100% - 280px);
  height: 100%;
  margin: 0 0 0 280px;
  @media (max-width: 640px) {
    width: 100%;
    margin: 0;
  }
  overflow-y: scroll !important;
}
//.image-header {
//  background-image: url('/assets/team-management.png');
//  background-repeat: no-repeat;
//  background-size: cover;
//  background-position: center;
//  width: 100%;
//  height: 203px;
//}
.content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  max-width: 600px;
  color: #2a4869;
}
//button {
//  margin-top: 30px;
//  width: 50%;
//  background-color: #cb8f2c;
//  border: none;
//  color: white;
//  cursor: pointer;
//  text-transform: uppercase;
//
//  &:hover {
//    color: white;
//  }
//  &:disabled {
//    pointer-events: none;
//    background-color: #e0e0e0;
//    box-shadow: none;
//    color: #a3a3a3;
//  }
//}
.title {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 12px;
}
//input {
//  background: transparent;
//  border: 1px solid black;
//  border-radius: 5px;
//}
@media (max-width: 640px) {
  .content-wrapper {
    padding: 0 15px;
  }
}
</style>
