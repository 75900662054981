<template>
  <div
    class="sidebar-burger-menu"
    @click="changeShowSidebar"
    v-if="!showSidebar"
  >
    <div class="menu-rows">
      <div class="menu-row" />
      <div class="menu-row" />
      <div class="menu-row" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SidebarBurgerMenu',
  methods: {
    ...mapActions({ changeShowSidebar: 'sidebarInfo/changeShowSidebar' }),
  },
  computed: {
    ...mapGetters({ showSidebar: 'sidebarInfo/getShowSidebar' }),
  },
};
</script>

<style lang="scss" scoped>
.sidebar-burger-menu {
  width: 44px;
  height: 44px;
  padding-left: 5px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .menu-rows {
    height: 20px;
    width: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .menu-row {
      width: 100%;
      height: 2px;
      background: #ffffff;
    }
  }
}
</style>
