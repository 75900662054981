export default {
  firestorePath: 'users/{userId}',
  firestoreRefType: 'doc',
  moduleName: 'users',
  statePropName: 'user',
  namespaced: true,
  state: {
    session: false,
  },
  mutations: {
    SET_SESSION(state, session) {
      // called from firebase.js
      state.session = session;
    },
    UNSET_SESSION(state) {
      state.session = false;
    },
  },
  actions: {
    clearUser({ commit }) {
      commit('UNSET_SESSION');
    },
  },
  getters: {
    getCurrentSession: state => {
      return state.session;
    },
  },
};
