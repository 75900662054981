<template>
  <div
    id="image-header"
    class="image-header"
    :style="{ backgroundImage: `url(${imagePath})` }"
  >
    <SidebarBurgerMenu />
  </div>
</template>

<script>
import SidebarBurgerMenu from '@/components/Dashboard/SidebarBurgerMenu';
export default {
  name: 'DashboardHeaderImage',
  components: { SidebarBurgerMenu },
  props: ['imagePath'],
};
</script>

<style lang="scss" scoped>
.image-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 203px;
  @media (max-width: 640px) {
    height: 100px;
    background-position: right;
  }
}
</style>
