<template>
  <div class="container mx-auto px-4">
    <loading
      :active.sync="loading"
      color="#00aaff"
      loader="dots"
      background-color="#4B4B4B"
      is-full-page
    ></loading>

    <!-- inputs -->
    <form @input="fieldUpdate">
      <div class="grid gap-2 grid-cols-2">
        <div class="input-group field-card align-items-center pb-5">
          <label class="text-center" for="employees"
            >How many employees are involved in systems engineering, excluding
            systems engineers?</label
          >
          <input
            name="employees"
            id="employees"
            type="number"
            v-model="employees"
            class="short-field absolute bottom-0"
            min="0"
          />
        </div>
        <div class="input-group field-card align-items-center pb-5">
          <label class="text-center" for="avg_cost_of_engineer">
            What is the total average non-systems engineer cost to the company?
          </label>
          <input
            name="avg_cost_of_engineer"
            id="avg_cost_of_engineer"
            v-model="avg_cost_of_engineer"
            class="short-field absolute bottom-0"
            v-currency="{ currency: 'USD', locale: 'en' }"
          />
        </div>
        <div class="input-group field-card align-items-center pb-5">
          <label class="text-center" for="a">
            What percentage of time do non-systems engineers spend on the
            systems engineering?
          </label>

          <div class="flex flex-wrap items-stretch absolute bottom-0">
            <input
              name="a"
              type="number"
              id="a"
              v-model="a"
              min="0"
              max="100"
              step="1"
              class="short-field"
            />
            <span
              class="z-10 h-full leading-snug font-normal absolute right-0 text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center py-3 mr-5"
            >
              <svg
                aria-hidden="true"
                data-prefix="fas"
                data-icon="percent"
                class="svg-inline--fa fa-percent fa-w-14 w-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M112 224c61.9 0 112-50.1 112-112S173.9 0 112 0 0 50.1 0 112s50.1 112 112 112zm0-160c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm224 224c-61.9 0-112 50.1-112 112s50.1 112 112 112 112-50.1 112-112-50.1-112-112-112zm0 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM392.3.2l31.6-.1c19.4-.1 30.9 21.8 19.7 37.8L77.4 501.6a23.95 23.95 0 01-19.6 10.2l-33.4.1c-19.5 0-30.9-21.9-19.7-37.8l368-463.7C377.2 4 384.5.2 392.3.2z"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="input-group field-card align-items-center pb-5">
          <label class="text-center" for="b">
            On average, how much time is spent correcting information errors in
            systems engineering?
          </label>

          <div class="flex flex-wrap items-stretch absolute bottom-0">
            <input
              type="number"
              name="b"
              id="b"
              v-model="b"
              min="0"
              max="100"
              step="1"
              class="short-field"
            />
            <span
              class="z-10 h-full leading-snug font-normal absolute right-0 text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center py-3 mr-5"
            >
              <svg
                aria-hidden="true"
                data-prefix="fas"
                data-icon="percent"
                class="svg-inline--fa fa-percent fa-w-14 w-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M112 224c61.9 0 112-50.1 112-112S173.9 0 112 0 0 50.1 0 112s50.1 112 112 112zm0-160c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm224 224c-61.9 0-112 50.1-112 112s50.1 112 112 112 112-50.1 112-112-50.1-112-112-112zm0 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM392.3.2l31.6-.1c19.4-.1 30.9 21.8 19.7 37.8L77.4 501.6a23.95 23.95 0 01-19.6 10.2l-33.4.1c-19.5 0-30.9-21.9-19.7-37.8l368-463.7C377.2 4 384.5.2 392.3.2z"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="input-group field-card align-items-center pb-5">
          <label class="text-center" for="c">
            Information error reduction in systems engineering due to MapleMBSE.
          </label>
          <i class="text-center text-sm"
            >(Assumes 75% based on Nissan case study.)</i
          >

          <div class="flex flex-wrap items-stretch absolute bottom-0">
            <input
              type="number"
              id="c"
              name="c"
              v-model="c"
              min="0"
              max="100"
              step="1"
              class="short-field"
            />
            <span
              class="h-full leading-snug font-normal absolute right-0 text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center py-3 mr-5"
            >
              <svg
                aria-hidden="true"
                data-prefix="fas"
                data-icon="percent"
                class="svg-inline--fa fa-percent fa-w-14 w-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M112 224c61.9 0 112-50.1 112-112S173.9 0 112 0 0 50.1 0 112s50.1 112 112 112zm0-160c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm224 224c-61.9 0-112 50.1-112 112s50.1 112 112 112 112-50.1 112-112-50.1-112-112-112zm0 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM392.3.2l31.6-.1c19.4-.1 30.9 21.8 19.7 37.8L77.4 501.6a23.95 23.95 0 01-19.6 10.2l-33.4.1c-19.5 0-30.9-21.9-19.7-37.8l368-463.7C377.2 4 384.5.2 392.3.2z"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="input-group field-card align-items-center pb-5">
          <label class="text-center" for="d">
            Productivity gain in using MapleMBSE compared to other tools.
          </label>
          <i class="text-center text-sm"
            >(Assumes 2x acceleration based on Nissan case study.)</i
          >
          <input
            id="d"
            name="d"
            type="number"
            v-model="d"
            class="short-field absolute bottom-0"
            min="0"
          />
        </div>
      </div>
    </form>

    <div class="flex justify-content-center mt-8">
      <table class="results-table table-auto">
        <tbody>
          <tr>
            <td class="font-bold">
              Total cost of non-systems engineers engaging in the systems
              engineering process
            </td>
            <td><input readonly :value="v" class="result-field" /></td>
          </tr>
          <tr>
            <td class="font-bold">
              Total monetary loss represented by correcting information errors
              in systems engineering
            </td>
            <td><input readonly :value="w" class="result-field" /></td>
          </tr>
          <tr>
            <td class="font-bold">
              Total cost reduction by reducing information errors in systems
              engineering
            </td>
            <td><input readonly :value="x" class="result-field" /></td>
          </tr>
          <tr>
            <td class="font-bold">
              Savings by accelerating non-systems engineer productivity with
              MapleMBSE
            </td>
            <td>
              <input
                readonly
                :value="y"
                class="result-field"
                v-currency="{ currency: 'USD', locale: 'en' }"
              />
            </td>
          </tr>
          <tr>
            <td class="font-bold">
              Total monetary savings between reducting information errors and
              accelerating productivity
            </td>
            <td><input readonly :value="z" class="result-field" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="grid justify-items-center">
      <button @click="goToForm()" class="btn detail-btn mt-5">
        SEE DETAILED COSTS
      </button>
    </div>
    <pre>
      <!-- {{ state }} -->
    </pre>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { debounce } from 'debounce';
import { unformat, formatMoney } from 'accounting-js';
export default {
  name: 'Calculator',
  components: {
    Loading,
  },
  data() {
    return {
      id: '',
      loading: true,
      status: 'new',
      errorMessage: '',
      employees: 30,
      avg_cost_of_engineer: 100000,
      a: 10,
      b: 10,
      c: 75,
      d: 2,
    };
  },
  computed: {
    v() {
      const employees = this.employees;
      const avg_cost_of_engineer = unformat(this.avg_cost_of_engineer);
      const a = this.a * 0.01; // convert percent to decimal
      return formatMoney(employees * avg_cost_of_engineer * a);
    },
    w() {
      const v = unformat(this.v);
      const b = this.b * 0.01;
      return formatMoney(v * b);
    },
    x() {
      const w = unformat(this.w);
      const c = this.c * 0.01;
      return formatMoney(w * c);
    },
    y() {
      const v = unformat(this.v);
      const d = this.d;
      return formatMoney(v / d);
    },
    z() {
      const x = unformat(this.x);
      const y = unformat(this.y);
      return formatMoney(x + y);
    },
  },
  methods: {
    goToForm() {
      let submissions = {
        employees: this.employees,
        avg_cost_of_engineer: this.avg_cost_of_engineer,
        a: this.a,
        b: this.b,
        c: this.c,
        d: this.d,
      };
      localStorage.setItem('submissions', JSON.stringify(submissions));
      this.save();
      this.$router.push('/form');
    },
    async save() {
      const db = this.$auth.db;
      try {
        let obj = {
          employees: this.employees,
          avg_cost_of_engineer: this.avg_cost_of_engineer,
          a: this.a,
          b: this.b,
          c: this.c,
          d: this.d,
          v: this.v,
          w: this.w,
          x: this.x,
          y: this.y,
          z: this.z,
          created: Date.now(),
        };
        const data = await db.collection('submissions').add(obj);
        const id = data.o_.id;
        this.id = id;
        this.status = 'synced';
      } catch (e) {
        this.errorMessage = JSON.stringify(e);
      }
    },
    async update() {
      console.log('update function invoked');
      const id = this.id;
      const db = this.$auth.db;

      try {
        let data = {
          employees: this.employees,
          avg_cost_of_engineer: this.avg_cost_of_engineer,
          a: this.a,
          b: this.b,
          c: this.c,
          d: this.d,
          v: this.v,
          w: this.w,
          x: this.x,
          y: this.y,
          z: this.z,
        };
        const docRef = db.collection('submissions').doc(id);
        await docRef.update(data);
      } catch (e) {
        this.errorMessage = JSON.stringify(e);
        this.status = 'error';
      }
    },
    fieldUpdate() {
      // if (this.status === 'new') {
      //   this.save();
      // } else if (this.status === 'synced') {
      //   this.status = 'modified';
      //   this.debouncedUpdateSubmission();
      // }
    },
    debouncedUpdateSubmission: debounce(function () {
      this.update();
    }, 3000),
  },
  async created() {
    this.loading = false;
    let submissions = JSON.parse(localStorage.getItem('submissions'));
    console.log(submissions);
    if (submissions) {
      this.employees = Number(submissions.employees);
      this.avg_cost_of_engineer = unformat(submissions.avg_cost_of_engineer);
      this.a = Number(submissions.a);
      this.b = Number(submissions.b);
      this.c = Number(submissions.c);
      this.d = Number(submissions.d);
    }
  },
};
</script>

<style lang="scss" scoped>
.detail-btn {
  background: #00aaff;
  color: white;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
</style>
