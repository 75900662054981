const state = {
  currentInfoComponentName: '',
  showSidebar: false,
};

const actions = {
  setCurrentInfoComponentName: async ({ commit }, name) => {
    commit('SET_CURRENT_INFO_COMPONENT_NAME', name);
  },
  watchShowSidebar: ({ commit }) => {
    const smBreakPoint = 640;
    commit('SET_SHOW_SIDEBAR', window.innerWidth > smBreakPoint);
    window.addEventListener('resize', () => {
      commit('SET_SHOW_SIDEBAR', window.innerWidth > smBreakPoint);
    });
  },
  unwatchShowSidebar: ({ commit }) => {
    window.removeEventListener('resize', () => {
      commit('SET_SHOW_SIDEBAR', window.innerWidth);
    });
  },
  changeShowSidebar: ({ commit, state }) => {
    commit('SET_SHOW_SIDEBAR', !state.showSidebar);
  },
};

const mutations = {
  SET_CURRENT_INFO_COMPONENT_NAME(state, name) {
    state.currentInfoComponentName = name;
  },
  SET_SHOW_SIDEBAR(state, newStatus) {
    state.showSidebar = newStatus;
  },
};

const getters = {
  getShowSidebar: state => {
    return state.showSidebar;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
