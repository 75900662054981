<template>
  <div class="calculator-parameters">
    <div
      class="parameters-title flex border-b border-gray-200 py-3 text-gray-500"
    >
      <div class="parameters-title-parameter">
        Parameter
      </div>
      <div class="parameters-title-value">Value</div>
    </div>

    <CalculatorParametersRow
      parameter-text="Utilization factor"
      state-name="utilizationFactor"
      :value="state.utilizationFactor"
      :disabled="loadingParameters"
      :currency-options="currencyOptions.utilizationFactor"
      @update-input="updateState"
    />

    <CalculatorParametersRow
      parameter-text="Base cost per seat per year"
      state-name="baseCost"
      :value="state.baseCost"
      :disabled="loadingParameters"
      :currency-options="currencyOptions.baseCost"
      @update-input="updateState"
    />

    <CalculatorParametersRow
      parameter-text="Multi-seat discount"
      state-name="multiSeatDiscount"
      :value="state.multiSeatDiscount"
      :disabled="loadingParameters"
      :currency-options="currencyOptions.multiSeatDiscount"
      @update-input="updateState"
    />

    <CalculatorParametersRow
      parameter-text="Training/support cost per year"
      state-name="trainingCost"
      :value="state.trainingCost"
      :disabled="loadingParameters"
      :currency-options="currencyOptions.trainingCost"
      @update-input="updateState"
    />

    <DashboardSettingsButton
      button-text="Submit"
      :on-click="submitParameters"
      :is-disabled="submitInProgress"
      :is-loading="submitInProgress"
    />
    <div v-if="submitStatus.message" class="mt-1" :class="submitMessageColor">
      {{ submitStatus.message }}
    </div>
  </div>
</template>

<script>
import {
  getCalculatorParameters,
  saveCalculatorParameters,
} from '@/services/CalculatorParametersService';
import CalculatorParametersRow from '@/components/DashboardSettings/CalculatorParameters/CalculatorParametersRow';
import DashboardSettingsButton from '@/components/DashboardSettings/DashboardSettingsButton';
import {
  currencyOptions,
  submitMessages,
} from '@/components/DashboardSettings/CalculatorParameters/data';

export default {
  name: 'CalculatorParameters',
  components: { DashboardSettingsButton, CalculatorParametersRow },
  data() {
    return {
      state: {
        utilizationFactor: '',
        baseCost: '',
        multiSeatDiscount: '',
        trainingCost: '',
      },
      loadingParameters: true,
      currencyOptions,
      submitStatus: {
        status: '',
        message: '',
      },
      submitInProgress: false,
    };
  },
  methods: {
    async submitParameters() {
      this.submitInProgress = true;
      const submitResult = await saveCalculatorParameters(
        this.$auth,
        this.state
      );
      this.showSubmitMessage(submitResult);
    },
    updateState(e) {
      this.state[e.stateName] = e.newValue;
    },
    showSubmitMessage(submitResult) {
      if (submitResult === 'success') {
        this.submitStatus.status = 'success';
        this.submitStatus.message = submitMessages.success;
        this.submitInProgress = false;
      }
      if (submitResult === 'error') {
        this.submitStatus.status = 'error';
        this.submitStatus.message = submitMessages.error;
        this.submitInProgress = false;
      }
      setTimeout(() => {
        this.clearSubmitStatus();
      }, 2000);
    },
    clearSubmitStatus() {
      this.submitStatus = {
        status: '',
        message: '',
      };
    },
  },
  computed: {
    submitMessageColor() {
      if (this.submitStatus.status === 'success') return 'text-success';
      return 'text-danger';
    },
  },
  mounted() {
    getCalculatorParameters(this.$auth).then(res => {
      this.loadingParameters = false;
      if (!res) return;
      this.state = res;
    });
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.calculator-parameters {
  padding: 0;
  color: #6b6c6c;
  width: 80%;
}
.parameters-title {
  &-parameter {
    padding-left: 0.5rem;
    width: 60%;
  }
  &-value {
    width: 40%;
  }
}
@media (max-width: 640px) {
  .calculator-parameters {
    width: 100%;
  }
  .parameters-title {
    &-parameter {
      padding-left: 0;
      width: 65%;
    }
    &-value {
      width: 35%;
    }
  }
}
</style>
