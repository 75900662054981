import Vue from 'vue';
import store from './store';
import Router from 'vue-router';
import Login from './pages/Login';
import Calculator from './pages/Calculator';
import Form from './pages/Form';
import Final from './pages/Final';
import Dashboard from './pages/Dashboard';
import Submissions from './pages/dashboard/Submissions';
import Settings from './pages/dashboard/Settings';
import FirebaseAuthPlugin from './plugins/firebase';

Vue.use(Router);
Vue.use(store);
Vue.use(FirebaseAuthPlugin);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      name: 'calculator',
      component: Calculator,
    },
    {
      path: '/form',
      name: 'form',
      component: Form,
    },
    {
      path: '/final',
      name: 'final',
      component: Final,
    },
    {
      path: '/submissions',
      name: 'submissions',
      component: Submissions,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        authRequired: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.users.session) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
