import Vue from 'vue';
import Vuex from 'vuex';
import users from './modules/users';
import sidebarInfo from './modules/sidebarInfo';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    sidebarInfo,
    users,
  },
});

export default store;
