<template>
  <div class="login container-fluid text-center p-0">
    <loading
      :active.sync="isLoading"
      color="#cb8f2c"
      loader="dots"
      :z-index="99"
      :opacity="0"
      transition="fade"
    ></loading>
    <div id="image-header" class="image-header" />
    <div class="container-fluid content">
      <form
        ref="form"
        class="form-signin needs-validation container"
        novalidate
        autocomplete="off"
        @submit.prevent="login()"
      >
        <input autocomplete="off" name="hidden" type="text" style="display:none;">
        <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
        <div class="form-group">
          <label for="inputEmail">Email address</label>
          <input
            v-model="email"
            type="email"
            id="inputEmail"
            :class="`form-control my-2 ${userError ? 'error' : ''}`"
            placeholder="Email address"
            required
            autofocus
            :autocomplete="false"
          />
          <div class="valid-feedback">Valid.</div>
          <div class="invalid-feedback">Please enter valid email address</div>
          <div class="error" v-if="userError">
            <div class="alert alert-danger" role="alert">
              <img src="/assets/error.svg" class="mr-2" />
              There is no user record corresponding to this identifier. The user may have been deleted.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="inputPassword">Password</label>
          <div class="password-input">
            <input
              v-model="password"
              type="password"
              id="inputPassword"
              :class="`form-control ${passwordError ? 'error' : ''}`"
              placeholder="Password"
              required
              :autocomplete="false"
            />
            <img
              v-if="!isShowingPassword && password.length > 0"
              class="show-password"
              src="/assets/show-password.svg"
              @click="handleShowPassword()"
              :width="25"
              :height="25"
            />
            <img
              v-if="isShowingPassword && password.length > 0"
              class="show-password"
              src="/assets/hide-password.svg"
              @click="handleShowPassword()"
              :width="25"
              :height="25"
            />
          </div>
          <div class="valid-feedback">Valid.</div>
          <div class="invalid-feedback">Please fill out this field.</div>
          <div class="error" v-if="passwordError">
            <div class="alert alert-danger" role="alert">
              <img src="/assets/error.svg" class="mr-2" />
              The password is invalid or the user does not have a password.
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="sign-in btn btn-block" type="submit" :disabled="isLoading || email.length < 1">
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      userError: false,
      passwordError: false,
      isShowingPassword: false,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters("users", ["getCurrentSession"]),
    nextRoute() {
      return this.$route.query.redirect || "/dashboard";
    }
  },
  watch: {
    getCurrentSession(auth) {
      if (auth) {
        this.$router.replace(this.nextRoute);
      }
    }
  },
  mounted() {},
  created() {
    const signInToken = this.$route.query.token;
    if (signInToken) {
      this.$auth.signInWithCustomToken(signInToken).catch(function(error) {
        // Handle Errors here.
        console.log(error);
      });
    }
  },
  methods: {
    async login() {
      const form = this.$refs.form;
      this.userError = false;
      this.passwordError = false;
      this.isLoading = true;
      if (!form.checkValidity()) {
        form.classList.add('was-validated');
        this.isLoading = false;
        return;
      }
      try {
        await this.$auth.login(this.email, this.password);
        this.isLoading = false;
      } catch (err) {
        const { message } = err;
        if (message.includes('no user')) {
          this.userError = true;
          this.passwordError = false;
        } else {
          this.userError = false;
          this.passwordError = true;
        }
        this.isLoading = false;
      }
    },
    handleShowPassword() {
      var passwordInput = document.getElementById("inputPassword");
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        this.isShowingPassword = true;
      } else {
        passwordInput.type = "password";
        this.isShowingPassword = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.login {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}

.image-header {
  background-image: url("/assets/dashboard-header.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 240px;
}

.content {
  height: 100%;
  background: #2a4869;
  padding-top: 30px;
}

.form-signin {
  width: 100%;
  max-width: 450px;
  padding: 15px;
  margin: auto;
  color: white;
}
.valid-feedback, .invalid-feedback {
  text-align: left;
}
.form-signin .checkbox {
  font-weight: 400;
}
.password-input {
  position: relative;
  .show-password {
    position: absolute;
    top: 10px;
    right: 10px;
    filter: invert(1) brightness(0.8);
    cursor: pointer;
    z-index: 100;
  }
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  background: transparent;
  border: 2px solid #7f8fa2;
  border-radius: 5px;
  color: white;

  &.error {
    border-color: #cc3313;
  }
}
.form-group label {
  width: 100%;
  text-align: left;
}
.form-signin .form-control:focus {
  z-index: 2;
}

.buttons {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .sign-in {
    width: 50%;
    background-color: #CB8F2C;
    border: none;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    &:disabled {
      pointer-events: none;
      background-color: #E0E0E0;
      box-shadow: none;
      color: #A3A3A3;
    }
  }
}
.alert {
  background: #cc3313;
  border: none;
  color: white;
  text-align: left;
  margin-top: 10px;
  img {
    filter: invert(1);
    transform: scale(1.2);
  }
}
.error {
  position: relative;
  margin-top: 20px;
}
.error:before, .container:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 30px;
  border: 8px solid transparent;
  border-bottom-color: #cc3313;
}

.error:after {
  left: 20px;
  border: 10px solid transparent;
  border-bottom-color: #ffffff;
}
</style>
