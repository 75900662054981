<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>
<style lang="scss">
body,
html,
#app {
  background-color: white;
  font-family: 'Lato', Helvetica, arial, sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  min-height: 100%;
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
@font-face {
  font-family: "Lato";
  src: local("Lato"),
   url(./fonts/Lato/Lato-Regular.ttf) format("truetype");
}
</style>
