<template>
  <div class="col-12 p-0 mt-12">
    <div class="text-lg font-semibold">
      Password
    </div>
    <div class="mt-4">
      To change your password, please click the button below and follow
      instructions sent to the email listed above.
    </div>
    <DashboardSettingsButton
      button-text="Send Email"
      :on-click="changePassword"
      :is-disabled="isSending"
    />
    <!--    <button-->
    <!--      class="btn btn-block"-->
    <!--      @click="changePassword()"-->
    <!--      :disabled="isSending"-->
    <!--    >-->
    <!--      Send Email-->
    <!--    </button>-->
  </div>
</template>

<script>
import UsersService from '@/services/UsersService';
import DashboardSettingsButton from '@/components/DashboardSettings/DashboardSettingsButton';

export default {
  name: 'ChangePassword',
  components: { DashboardSettingsButton },
  mixins: [UsersService],
  data() {
    return {
      email: '',
      isSending: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    changePassword() {
      this.isSending = true;
      try {
        this.$auth.sendPasswordResetEmail(this.email);
        this.$vex.dialog.alert({
          message: `A password reset email has been sent to ${this.email}.`,
          placeholder: 'Planet name',
          className: 'vex-theme-os',
        });
      } catch (e) {
        console.log(e);
      }
      this.isSending = false;
    },
  },
  async mounted() {
    this.email = await this.$auth.currentUserEmail();
  },
};
</script>

<style scoped></style>
