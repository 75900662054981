<template>
  <div class="calculator-parameters-row">
    <div class="name-wrapper">
      <div class="name">
        {{ parameterText }}
      </div>
    </div>
    <div class="value-wrapper">
      <div class="value-input">
        <input
          class="form-control"
          :value="value"
          @blur="updateInput"
          :disabled="loading"
          v-currency="currencyOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalculatorParametersRow',
  props: ['parameterText', 'value', 'loading', 'currencyOptions', 'stateName'],
  methods: {
    updateInput(e) {
      this.$emit('update-input', {
        stateName: this.stateName,
        newValue: e.target.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.calculator-parameters-row {
  width: 100%;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.name-wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
}
.name {
  color: #6b6c6c;
  font-weight: 500;
  padding-left: 0.5rem;
}
.value-wrapper {
  width: 40%;
}
.value-input {
  width: 50%;
}
.form-control {
  color: #4a5158 !important;
  font-weight: 500;
}
@media (max-width: 640px) {
  .name-wrapper {
    width: 65%;
  }
  .name {
    padding-left: 0;
  }
  .value-wrapper {
    width: 35%;
  }
  .value-input {
    width: 100%;
  }
}
</style>
