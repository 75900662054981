import { formatMoney, formatNumber, unformat } from 'accounting-js';

export async function saveCalculatorParameters($authInstance, formattedData) {
  const calculatorParameters = unformatDataBeforeSave(formattedData);
  const { getCurrentUserUid, db } = $authInstance;
  const userUid = await getCurrentUserUid();

  return await db
    .collection('users')
    .doc(userUid)
    .set({ calculatorParameters })
    .then(() => 'success')
    .catch(() => 'error');
}

export async function getCalculatorParameters($authInstance) {
  const { getCurrentUserUid, db } = $authInstance;
  const userUid = await getCurrentUserUid();

  try {
    const userData = (
      await db
        .collection('users')
        .doc(userUid)
        .get()
    ).data();
    return formatResponse(userData.calculatorParameters);
  } catch (e) {
    const defaultValues = getDefaultValues();
    await saveCalculatorParameters($authInstance, defaultValues);
    return formatResponse(defaultValues);
  }
}

function unformatDataBeforeSave(data) {
  return {
    utilizationFactor: unformat(data.utilizationFactor),
    baseCost: unformat(data.baseCost),
    multiSeatDiscount: unformat(data.multiSeatDiscount),
    trainingCost: unformat(data.trainingCost),
  };
}

function formatResponse(res) {
  return {
    utilizationFactor: formatNumber(res.utilizationFactor, {
      precision: 1,
    }),
    baseCost: formatMoney(res.baseCost, { symbol: '$', precision: 0 }),
    multiSeatDiscount: formatMoney(res.multiSeatDiscount, {
      symbol: '%',
      precision: 0,
      format: '%v %s',
    }),
    trainingCost: formatMoney(res.trainingCost, {
      symbol: '$',
      precision: 0,
    }),
  };
}

function getDefaultValues() {
  return {
    utilizationFactor: 1.5,
    baseCost: 5000,
    multiSeatDiscount: 10,
    trainingCost: 1000,
  };
}
