<template>
  <div class="container mx-auto px-4 font-bold">
    <div class="flex justify-content-center my-16">
      <table class="results-table table-auto w-100">
        <tbody>
          <tr>
            <td>
              Number of non-systems engineers in systems engineering projects
            </td>
            <td>{{ employees }}</td>
          </tr>
          <tr>
            <td>
              Percentage of time non-systems engineers spend on systems
              engineering tasks
            </td>
            <td>{{ a }}%</td>
          </tr>
          <tr>
            <td>
              Average total cost of a non-systems engineer in your company
            </td>
            <td>{{ e }}</td>
          </tr>
          <tr>
            <td>
              Average time spent in correcting errors in the systems model
            </td>
            <td>{{ b }}%</td>
          </tr>
          <tr>
            <td>
              Information error reduction in systems engineering due to
              MapleMBSE
            </td>
            <td>{{ c }}%</td>
          </tr>
          <tr>
            <td>
              Productivity gain in using MapleMBSE compared to other tools
            </td>
            <td>{{ d }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex justify-content-center my-16">
      <table class="results-table table-auto w-100">
        <tbody>
          <tr>
            <td>
              Total cost of non-systems engineers engaging in the systems
              engineering process
            </td>
            <td>{{ v }}</td>
          </tr>
          <tr>
            <td>
              Total monetary loss represented by correcting information errors
              in systems engineering
            </td>
            <td>{{ w }}</td>
          </tr>
          <tr>
            <td>
              Total cost reduction by reducing information errors in systems
              engineering
            </td>
            <td>{{ x }}</td>
          </tr>
          <tr>
            <td>
              Savings by accelerating non-systems engineer productivity with
              MapleMBSE
            </td>
            <td>
              {{ y }}
            </td>
          </tr>
          <tr>
            <td>
              Total monetary savings between reducting information errors and
              accelerating productivity
            </td>
            <td>{{ z }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex justify-content-center my-16">
      <table class="results-table table-auto w-100">
        <tbody>
          <tr>
            <td>Utilization rate per user</td>
            <td>{{ p }}%</td>
          </tr>
          <tr>
            <td>Number of users per seat</td>
            <td>{{ q }}</td>
          </tr>
          <tr>
            <td>Number of MapleMBSE seats</td>
            <td>{{ r }}</td>
          </tr>
          <tr>
            <td>Base cost per seat per year</td>
            <td>{{ s }}</td>
          </tr>
          <tr>
            <td>Multi-seat discount per yearear</td>
            <td>{{ multiSeatDiscount }}%</td>
          </tr>
          <tr>
            <td>Total cost per seat per year</td>
            <td>{{ t }}</td>
          </tr>
          <tr>
            <td>License cost per year</td>
            <td>{{ u }}</td>
          </tr>
          <tr>
            <td>Training/support cost per year</td>
            <td>{{ f }}</td>
          </tr>
          <tr>
            <td>Total implementation cost</td>
            <td>{{ g }}</td>
          </tr>
          <tr class="detail-btn">
            <td>Net savings per year</td>
            <td>{{ h }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { unformat, formatMoney } from 'accounting-js';

export default {
  name: 'Final',
  data() {
    return {
      employees: 30,
      avg_cost_of_engineer: 100000,
      a: 10,
      b: 10,
      c: 75,
      d: 2,
      calculatorParameters: {},
    };
  },
  created() {
    let submissions = JSON.parse(localStorage.getItem('submissions'));
    if (submissions) {
      this.employees = Number(submissions.employees);
      this.avg_cost_of_engineer = unformat(submissions.avg_cost_of_engineer);
      this.a = Number(submissions.a);
      this.b = Number(submissions.b);
      this.c = Number(submissions.c);
      this.d = Number(submissions.d);
    }
    const db = this.$auth.db;
    return db
      .collection('users')
      .get()
      .then((res) => {
        this.calculatorParameters = res.docs[0].data()[
          'calculatorParameters'
        ];
      });
  },
  computed: {
    baseCost() {
      return (
        (this.calculatorParameters &&
          this.calculatorParameters['baseCost']) ||
        0
      );
    },
    multiSeatDiscount() {
      return (
        (this.calculatorParameters &&
          this.calculatorParameters['multiSeatDiscount']) ||
        0
      );
    },
    trainingCost() {
      return (
        (this.calculatorParameters &&
          this.calculatorParameters['trainingCost']) ||
        0
      );
    },
    utilizationFactor() {
      return (
        (this.calculatorParameters &&
          this.calculatorParameters['utilizationFactor']) ||
        0
      );
    },
    e() {
      return formatMoney(this.avg_cost_of_engineer);
    },
    f() {
      return formatMoney(this.r < 5 ? 10000 : (this.r / 5) * 10000);
    },
    g() {
      return formatMoney(unformat(this.f) + unformat(this.u));
    },
    h() {
      return formatMoney(unformat(this.z) - unformat(this.g));
    },
    p() {
      return this.b * this.utilizationFactor;
    },
    q() {
      return Math.ceil(100 / this.p);
    },
    r() {
      return Math.ceil(this.employees / this.q);
    },
    s() {
      return formatMoney(this.baseCost);
    },
    u() {
      return formatMoney(
        this.baseCost * this.r * (1 - this.multiSeatDiscount / 100)
      );
    },
    t() {
      return formatMoney(unformat(this.u) / this.r);
    },
    v() {
      const employees = this.employees;
      const avg_cost_of_engineer = unformat(this.avg_cost_of_engineer);
      const a = this.a * 0.01; // convert percent to decimal
      return formatMoney(employees * avg_cost_of_engineer * a);
    },
    w() {
      const v = unformat(this.v);
      const b = this.b * 0.01;
      return formatMoney(v * b);
    },
    x() {
      const w = unformat(this.w);
      const c = this.c * 0.01;
      return formatMoney(w * c);
    },
    y() {
      const v = unformat(this.v);
      const d = this.d;
      return formatMoney(v / d);
    },
    z() {
      const x = unformat(this.x);
      const y = unformat(this.y);
      return formatMoney(x + y);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.detail-btn {
  background: #00aaff !important;
  color: white;
  font-size: 2rem;
}
</style>