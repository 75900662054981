<template>
  <div class="container mx-auto px-4">
    <h3 class="text-center font-bold text-4xl mt-5 mb-4">
      Complete this form to access your detailed costs
    </h3>
    <form
      ref="form"
      class="needs-validation container"
      autocomplete="off"
      @submit.prevent="fieldUpdate()"
    >
      <div
        class="grid grid-cols-1 md:grid-cols-2 gap-5 border-gray-200 border-2 rounded-3xl px-5 py-5"
      >
        <div class="input-group field-card md:col-span-2">
          <label for="email" class="text-2xl">Email Address:</label>
          <input
            name="email"
            id="email"
            type="email"
            v-model="email"
            class="text-2xl border-gray-200 border-2 rounded-full px-3 py-1 w-100"
            required
            autofocus
            :autocomplete="false"
          />
        </div>
        <div class="input-group field-card">
          <label for="fname" class="text-2xl">First Name:</label>
          <input
            name="fname"
            id="fname"
            type="text"
            v-model="fname"
            class="text-2xl border-gray-200 border-2 rounded-full px-3 py-1 w-100"
            required
            :autocomplete="false"
          />
        </div>
        <div class="input-group field-card">
          <label for="lname" class="text-2xl">Last Name:</label>
          <input
            name="lname"
            id="lname"
            type="text"
            v-model="lname"
            class="text-2xl border-gray-200 border-2 rounded-full px-3 py-1 w-100"
            required
            :autocomplete="false"
          />
        </div>
        <div class="input-group field-card">
          <label for="company" class="text-2xl">Company/Institution:</label>
          <input
            name="company"
            id="company"
            type="text"
            v-model="company"
            class="text-2xl border-gray-200 border-2 rounded-full px-3 py-1 w-100"
            required
            :autocomplete="false"
          />
        </div>
        <div class="input-group field-card">
          <label for="job_title" class="text-2xl">Job title:</label>
          <input
            name="job_title"
            id="job_title"
            type="text"
            v-model="job_title"
            class="text-2xl border-gray-200 border-2 rounded-full px-3 py-1 w-100"
          />
        </div>
        <div class="input-group field-card">
          <label for="country" class="text-2xl">Country:</label>
          <v-select
            class="text-2xl style-chooser"
            :options="countries"
            name="country"
            id="country"
            v-model="country"
          ></v-select>
        </div>
        <div class="input-group field-card">
          <label for="region" class="text-2xl">Region:</label>
          <input
            name="region"
            id="region"
            type="text"
            v-model="region"
            class="text-2xl border-gray-200 border-2 rounded-full px-3 py-1 w-100"
          />
        </div>
      </div>
      <div class="text-center">
        <button type="submit" class="btn detail-btn my-5">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Form',
  data() {
    return {
      email: '',
      fname: '',
      lname: '',
      company: '',
      job_title: '',
      country: 'United States',
      region: '',
      errorMessage: '',
      countries: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'American Samoa',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antarctica',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Aruba',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas (the)',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bermuda',
        'Bhutan',
        'Bolivia (Plurinational State of)',
        'Bonaire, Sint Eustatius and Saba',
        'Bosnia and Herzegovina',
        'Botswana',
        'Bouvet Island',
        'Brazil',
        'British Indian Ocean Territory (the)',
        'Brunei Darussalam',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cabo Verde',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cayman Islands (the)',
        'Central African Republic (the)',
        'Chad',
        'Chile',
        'China',
        'Christmas Island',
        'Cocos (Keeling) Islands (the)',
        'Colombia',
        'Comoros (the)',
        'Congo (the Democratic Republic of the)',
        'Congo (the)',
        'Cook Islands (the)',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Curaçao',
        'Cyprus',
        'Czechia',
        "Côte d'Ivoire",
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic (the)',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Eswatini',
        'Ethiopia',
        'Falkland Islands (the) [Malvinas]',
        'Faroe Islands (the)',
        'Fiji',
        'Finland',
        'France',
        'French Guiana',
        'French Polynesia',
        'French Southern Territories (the)',
        'Gabon',
        'Gambia (the)',
        'Georgia',
        'Germany',
        'Ghana',
        'Gibraltar',
        'Greece',
        'Greenland',
        'Grenada',
        'Guadeloupe',
        'Guam',
        'Guatemala',
        'Guernsey',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Heard Island and McDonald Islands',
        'Holy See (the)',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran (Islamic Republic of)',
        'Iraq',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jersey',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        "Korea (the Democratic People's Republic of)",
        'Korea (the Republic of)',
        'Kuwait',
        'Kyrgyzstan',
        "Lao People's Democratic Republic (the)",
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macao',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands (the)',
        'Martinique',
        'Mauritania',
        'Mauritius',
        'Mayotte',
        'Mexico',
        'Micronesia (Federated States of)',
        'Moldova (the Republic of)',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Montserrat',
        'Morocco',
        'Mozambique',
        'Myanmar',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands (the)',
        'New Caledonia',
        'New Zealand',
        'Nicaragua',
        'Niger (the)',
        'Nigeria',
        'Niue',
        'Norfolk Island',
        'Northern Mariana Islands (the)',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Palestine, State of',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines (the)',
        'Pitcairn',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Republic of North Macedonia',
        'Romania',
        'Russian Federation (the)',
        'Rwanda',
        'Réunion',
        'Saint Barthélemy',
        'Saint Helena, Ascension and Tristan da Cunha',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Martin (French part)',
        'Saint Pierre and Miquelon',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Sint Maarten (Dutch part)',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Georgia and the South Sandwich Islands',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan (the)',
        'Suriname',
        'Svalbard and Jan Mayen',
        'Sweden',
        'Switzerland',
        'Syrian Arab Republic',
        'Taiwan',
        'Tajikistan',
        'Tanzania, United Republic of',
        'Thailand',
        'Timor-Leste',
        'Togo',
        'Tokelau',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Turks and Caicos Islands (the)',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates (the)',
        'United Kingdom of Great Britain and Northern Ireland (the)',
        'United States Minor Outlying Islands (the)',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Venezuela (Bolivarian Republic of)',
        'Viet Nam',
        'Virgin Islands (British)',
        'Virgin Islands (U.S.)',
        'Wallis and Futuna',
        'Western Sahara',
        'Yemen',
        'Zambia',
        'Zimbabwe',
        'Åland Islands',
      ],
    };
  },
  methods: {
    async fieldUpdate() {
      const form = this.$refs.form;
      if (!form.checkValidity()) {
        form.classList.add('was-validated');
        return;
      }
      const db = this.$auth.db;
      try {
        let obj = {
          email: this.email,
          fname: this.fname,
          lname: this.lname,
          company: this.company,
          job_title: this.job_title,
          country: this.country,
          region: this.region,
          created: Date.now(),
        };
        await db.collection('userProfiles').add(obj);
        this.$router.push('/final');
      } catch (e) {
        this.errorMessage = JSON.stringify(e);
        console.log(this.errorMessage);
      }
    },
  },
};
</script>

<style>
.style-chooser .vs__dropdown-toggle {
  border-radius: 22.5px;
}
</style>
<style lang="scss" scoped>
.detail-btn {
  background: #00aaff;
  color: white;
  font-size: 2rem;
  padding-left: 100px;
  padding-right: 100px;
  font-weight: bold;
}
</style>