<template>
  <div class="submissions container-fluid p-0">
    <loading
      :active.sync="isLoading"
      color="#cb8f2c"
      loader="dots"
      background-color="#4B4B4B"
      is-full-page
    ></loading>
    <DashboardHeaderImage image-path="'/assets/submissions.png'" />
    <div class="content">
      <div class="header">
        <DashboardBreadcrumb logo="/assets/submissions-navy.svg" title-text="Submissions"/>
      </div>
      <div class="card">
        <div class="card-body">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th style="border: none;">
                  <label class="form-checkbox">
                    <input
                      type="checkbox"
                      v-model="isSelectAll"
                      @click="select"
                    />
                    <span class="checkmark"></span>
                  </label>
                </th>
                <th style="border: none;">
                  <div class="table-header">ID</div>
                </th>

                <th style="border: none;">
                  <div class="table-header">Date</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(submission, index) in submissions" :key="index">
                <td style="background: white; vertical-align: bottom;">
                  <label class="form-checkbox">
                    <input
                      type="checkbox"
                      :value="submission.id"
                      v-model="selected"
                      @change="onSelect()"
                    />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td style="background: white;">
                  <div class="table-row">{{ submission.id }}</div>
                </td>
                <td style="background: white;">
                  <div class="table-row">{{ submission.date }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div
          v-if="submissions.length > 0"
          class="pagination d-flex justify-content-end align-items-center"
        >
          <div v-if="!isLoading" class="pagination-bar">
            {{ from }} - {{ to }}
          </div>
          <button
            class="btn"
            :disabled="isLoading || from === 1"
            @click="onPrev()"
          >
            Prev
          </button>
          <button
            class="btn"
            :disabled="isLoading || to === counts"
            @click="onNext()"
          >
            Next
          </button>
        </div>
      </div>
      <div class="export-csv">
        <button
          :class="
            `btn export-button ${
              selectedSubmissions.length < 1 ? 'disabled' : ''
            }`
          "
          :disabled="selected.length < 1"
        >
          <JsonCSV class="export" :data="wholeSelectedSubmissions">
            EXPORT SELECTED
          </JsonCSV>
        </button>
        <button
          :class="
            `btn export-button export-all ${
              submissions.length < 1 ? 'disabled' : ''
            }`
          "
          :disabled="submissions.length < 1"
        >
          <JsonCSV class="export" :data="allSubmissions">
            EXPORT ALL
          </JsonCSV>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonCSV from 'vue-json-csv';
import { find } from 'lodash';
import moment from 'moment';
import DashboardHeaderImage from '@/components/Dashboard/DashboardHeaderImage';
import DashboardBreadcrumb from "@/components/Dashboard/DashboardBreadcrumb";

export default {
  name: 'Submissions',
  components: {
    DashboardBreadcrumb,
    DashboardHeaderImage,
    Loading,
    JsonCSV,
  },
  data() {
    return {
      isLoading: false,
      submissions: [],
      counts: 0,
      from: 1,
      to: 10,
      isSelectAll: false,
      selectedSubmissions: [],
      selected: [],
      wholeSelected: [],
      wholeSelectedSubmissions: [],
      allSubmissions: [],
    };
  },
  methods: {
    onSelect() {
      this.isSelectAll = this.selected.length === this.submissions.length;
      this.selectedSubmissions = [];
      for (let i in this.selected) {
        let customSelected = find(this.submissions, ['id', this.selected[i]]);

        // push customSelected to selectedSubmissions array
        this.selectedSubmissions.push(Object.assign({}, customSelected));
      }
      for (let j in this.selectedSubmissions) {
        if (
          !find(this.wholeSelectedSubmissions, [
            'id',
            this.selectedSubmissions[j].id,
          ])
        ) {
          this.wholeSelectedSubmissions.push(this.selectedSubmissions[j]);
        }
      }
    },
    async getSubmissionCounts() {
      this.isLoading = true;
      try {
        const db = this.$auth.db;
        const collectionRef = await db.collection('submissions');
        const counts = await collectionRef.get();
        this.counts = counts.size;
        if (this.counts < 10) {
          this.to = this.counts;
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async getSubmissionList() {
      this.isLoading = true;
      try {
        const db = this.$auth.db;
        let size = 10;
        if (this.counts < 10) {
          size = this.counts;
        } else {
          size = this.to - this.from + 1;
        }

        let from = this.from;
        const collectionRef = await db.collection('submissions');
        const snapshot = await collectionRef.get();
        let submissions = [];
        let index = 0;
        snapshot.forEach(doc => {
          submissions.push({
            id: doc.id,
            ...doc.data(),
          });
          index += 1;
        });

        let finalSubmissions = submissions;

        const fromValue = parseInt(from, 10);
        const sizeValue = parseInt(size, 10);
        const sortedSubmissions = _.orderBy(
          finalSubmissions,
          ['created'],
          ['desc']
        );
        const result = sortedSubmissions.slice(
          fromValue - 1,
          fromValue + sizeValue - 1
        );

        this.submissions = result.map(item => {
          return {
            id: item.id,
            employees: item.employees,
            avg_cost_of_engineer: item.avg_cost_of_engineer,
            a: item.a,
            b: item.b,
            c: item.c,
            d: item.d,
            v: item.v,
            w: item.w,
            x: item.x,
            y: item.y,
            z: item.z,
            date: moment(item.created).format('DD MMM YYYY hh:mm a'),
          };
        });
        this.selected = this.wholeSelected.filter(
          selected => !!find(this.submissions, ['id', selected])
        );
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    async getAllSubmissions() {
      try {
        const db = this.$auth.db;
        // const { data: submissionData } = await this.getAllSubmissions();
        const collectionRef = await db.collection('submissions');
        const snapshot = await collectionRef.get();
        let submissionData = [];
        let index = 0;
        snapshot.forEach(doc => {
          submissionData.push({
            id: doc.id,
            ...doc.data(),
          });
          index += 1;
        });

        this.allSubmissions = [];
        this.allSubmissions = submissionData.map(item => {
          return {
            id: item.id,
            employees: item.employees,
            avg_cost_of_engineer: item.avg_cost_of_engineer,
            a: item.a,
            b: item.b,
            c: item.c,
            d: item.d,
            v: item.v,
            w: item.w,
            x: item.x,
            y: item.y,
            z: item.z,
            date: item.created
              ? moment(item.created).format('DD MMM YYYY hh:mm a')
              : moment().format('DD MMM YYYY hh:mm a'),
          };
        });
      } catch (err) {
        console.log(err);
      }
    },
    async onPrev() {
      if (this.from > 10) {
        this.from -= 10;
      } else {
        this.from = 1;
      }
      this.to = this.from + 9;

      this.wholeSelected = this.wholeSelected.concat(
        this.selected.slice().filter(item => !this.wholeSelected.includes(item))
      );
      this.selected = [];
      await this.getSubmissionList();
    },
    async onNext() {
      if (this.to + 9 <= this.counts) {
        this.from += 10;
        this.to = this.from + 9;
      } else {
        this.from += 10;
        this.to = this.counts;
      }
      this.wholeSelected = this.wholeSelected.concat(
        this.selected.slice().filter(item => !this.wholeSelected.includes(item))
      );
      this.selected = [];
      await this.getSubmissionList();
    },
    select() {
      this.selected = [];
      this.selectedSubmissions = [];
      if (!this.isSelectAll) {
        for (let i in this.submissions) {
          this.selected.push(this.submissions[i].id);
          this.selectedSubmissions.push({
            id: this.submissions[i].id,
            date: this.submissions[i].created,
          });
        }
      }
    },
  },
  async mounted() {
    await this.getSubmissionCounts();
    await this.getSubmissionList();
    await this.getAllSubmissions();
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: none;
  overflow-y: auto;
  height: 520px;
}
.card-body {
  padding: 0;
}
.submissions {
  width: calc(100% - 280px);
  height: 100%;
  margin: 0 0 0 280px;
  overflow-y: scroll;
  @media (max-width: 640px) {
    width: 100%;
    margin: 0;
  }
}
.header {
  margin-bottom: 40px;
  color: #2a4869;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  text-align: left;
  text-transform: capitalize;
}
//.image-header {
//  background-image: url('/assets/submissions.png');
//  background-repeat: no-repeat;
//  background-size: cover;
//  background-position: center;
//  width: 100%;
//  height: 203px;
//}
.content {
  margin: 20px 4rem 20px 4rem;
}
.pagination {
  padding: 0 1.25rem 1.25rem 0;
}
.table-header {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 25px;
  color: #6c6c6c;
}
.table-row {
  font-family: Lato;
  font-size: 18px;
  line-height: 30px;
  color: #6c6c6c;
}
.export-csv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.export-button {
  background-color: #cb8f2c;
  border: none;
  color: white;
  cursor: pointer;

  ::v-deep .export {
    font-size: 14px;
    width: 100%;
    padding: 5px 55px 5px 55px;
  }
}
.export-button:hover {
  color: white;
  ::v-deep .export {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    padding: 5px 55px 5px 55px;
  }
}
.export-all {
  margin-left: 45px;
}
.export-button.disabled {
  pointer-events: none;
  background-color: #e0e0e0;
  box-shadow: none;
  color: #a3a3a3;
}

.form-checkbox {
  display: block;
  position: relative;
  padding-left: 13px;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 31.54px; */
  cursor: pointer;

  .option-title {
    text-align: left;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 2px solid darkgray;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: #2a4869;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 3px;
    top: 0px;
    width: 9px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(31deg);
    -webkit-transform: rotate(31deg);
    -ms-transform: rotate(31deg);
    transform: rotate(31deg);
  }

  div {
    color: #2a4869;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: -3px;
    margin-left: 9px;
  }
}
</style>
