/**
 * Read more:
 * https://medium.com/@MichalBujalski/reactive-firebase-auth-with-vue-92577ab78ff6
 */
// import store from "./store/index.js";
import Firebase from 'firebase';
import store from '../store';

import apiClient from '../services/ApiService';
import * as Cookies from 'js-cookie';

// Your web app's Firebase configuration
console.log(process.env);
const config = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};

export default {
  install: Vue => {
    const firebase = Firebase.initializeApp(config);
    const auth = firebase.auth();
    const db = firebase.firestore();

    Vue.prototype.$auth = {
      db,
      login: async (username, pass) => {
        await auth.signInWithEmailAndPassword(username, pass);
        await Cookies.set(
          'token',
          await firebase.auth().currentUser.getIdToken()
        );
      },
      logout: async () => {
        await auth.signOut();
        Cookies.remove('token');
      },
      sendPasswordResetEmail: async email => {
        await auth.sendPasswordResetEmail(email);
      },
      createUserWithEmailAndPassword: (email, password) => {
        return auth.createUserWithEmailAndPassword(email, password);
      },
      signInWithCustomToken(token) {
        return auth.signInWithCustomToken(token);
      },
      currentUserEmail: async () => {
        return await firebase.auth().currentUser.email;
      },
      getCurrentUserUid: () => {
        return firebase.auth().currentUser.uid;
      },
    };

    auth.onAuthStateChanged(async user => {
      if (user) {
        apiClient.interceptors.request.use(
          async config => {
            config.headers.authorization = Cookies.get('token');

            return config;
          },
          error => {
            console.log(error);
            return Promise.reject(error);
          }
        );
      } else {
        Cookies.remove('token');
      }
      store.commit('users/SET_SESSION', user || false);
    });

    apiClient.interceptors.request.use(
      async config => {
        config.headers.authorization = Cookies.get('token');
        return config;
      },
      error => {
        // handle the error
        return Promise.reject(error);
      }
    );

    apiClient.interceptors.response.use(undefined, function(err) {
      return new Promise(function() {
        if (err.response.status === 401) {
          if (err.response.data.response.message === 'Token expired') {
            auth.signOut();
            Cookies.remove('token');
            store.commit('users/SET_SESSION', false);
            window.location.href = '/';
          }
        }
        throw err;
      });
    });
  },
};
